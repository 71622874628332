<template>
    <div class="list-group-item">
        <div class="elementPreview d-block d-lg-flex">
            <div class="flex-shrink-1 result-image-wrapper">
                <searchedPortrait ref="facePreview" :portrait="portrait"></searchedPortrait>
                <span class="score-photo">
                    <i class="feather icon-chevron-right"></i>
                    <span v-if="result.score" :content="$t('photo_fragment.similarity_percentage')"
                        v-tippy="{ placement: 'top', arrow: true }">
                        {{ Number((result.score).toFixed(1)) }}%
                    </span>
                </span>
                <photo-fragment :meta="meta" :photo="result" :source="result.source" :is-child="true" />
            </div>

            <b-modal style="min-height: 640px" centered v-model="result.isPhotoSegments" scroll="keep" hide-footer>
                <pinch-zoom>
                    <image-profile style="margin-bottom: 80px;margin-top: 80px" :modal="true" :payload="result.image" />
                </pinch-zoom>
            </b-modal>
            <div class="flex-grow-1 ms-lg-3 photo-result-text">
                <div class="align-items-center align-self-start flex-row d-flex m-l-lg-15"
                    style="padding-left: 8px;height: 100%; width: 100%">
                    <div v-if="result.type !== 'face'" class="is-24x24 d-flex m-r-5">
                        <img :src="result.icon" :alt="result.param">
                    </div>
                    <div class="m-b-10 mb-lg-0 link-section">
                        <div v-if="result.type === 'database'">
                            {{ typeof result.source_locales !== 'undefined' ? result.source_locales[locale] :
                            result.source }}
                        </div>
                        <span class="link-text" v-else-if="typeof result.source_url !== 'undefined'">{{
                            $t("result.source") }}:
                            <span
                                v-html="$options.filters.isLink($options.filters.makeEmoji(result.source_url.toString()))"></span>
                        </span>
                        <span v-else-if="typeof result.data.data[1] !== 'undefined' && isAnyUrl(result.data)">
                            {{ $t('params.' + result.data[indexOfUrl(result.data)].param) | paramName }}:
                            <span class="link-text"
                                v-html="$options.filters.isLink($options.filters.makeEmoji(result.data[indexOfUrl(result.data)].value.toString()))"></span>
                        </span>
                        <span v-else>
                            {{ $t('params.' + result.data.data[0].param) | paramName }}:
                            <span class="link-text"
                                v-html="$options.filters.isLink($options.filters.makeEmoji(result.data[0].value.toString()))"></span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-lg-flex d-grid align-items-lg-center  m-b-lg-0">
                <button class="btn btn-outline-primary d-lg-none" v-if="result.data.data.length"
                    @click="displayDetail = !displayDetail">
                    <i class="feather"
                        :class="{ 'icon-chevron-up': displayDetail, 'icon-chevron-down': !displayDetail }"></i>
                </button>
                <i class="feather d-none d-lg-block" v-if="result.data.data.length"
                    @click="displayDetail = !displayDetail"
                    :class="{ 'icon-chevron-up': displayDetail, 'icon-chevron-down': !displayDetail }"></i>
            </div>
            <figure v-if="!editResults" class="d-lg-flex d-grid align-items-center m-b-0 delete-result"
                @click="deleteResultModal()">
                <button class="btn btn-default">
                    <i class="feather icon-x" :content="$t('modal.deleteResult')"
                        v-tippy="{ placement: 'top', arrow: true }"></i>
                </button>
            </figure>
            <figure class="d-flex justify-content-center align-items-center m-b-0">

                <div class="form-check m-l-10 m-t-10 mt-lg-0" v-show="editResults"
                    :content="$t('result.select_element')" v-tippy="{ placement: 'top', arrow: true }"><input
                        type="checkbox" @change="addToSelected" v-model="selected" class="form-check-input"></div>
            </figure>

        </div>

        <div class="elementDetails" v-show="displayDetail">
            <div class="list-group" v-if="result.data && result.data.data.length">

                <InfoBlock v-for="(param, index) in result.data.data" :key="index" :dataParam="param"
                    :resultTitle="result.param" :resultSource="result.source" />
            </div>
            <div class="wrapperNoResult" v-else>
                <div class="wrapperHeader">{{ moment(result.data.info_date, 'X').format('DD.MM.YYYY, HH:mm') }}</div>
                <div class="wrapperContent"> {{ $t("result.source") }}: {{ result.source }}</div>
            </div>
        </div>
        <b-modal ref="delete-modal" :title=" $t('result.delete_selected')" hide-footer centered>
            <div class="d-grid">
                <b-button class="mt-3" variant="danger" block @click="deleteSelectedResult()">{{
                $t('modal.actionDelete') }}</b-button>
                <b-button class="mt-2" variant="secondary" block @click="hideModal">{{ $t('modal.cancel') }}</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import searchedFace from '@/components/results/searchedFace.vue'
import filters from '../../mixins/filters';
import moment from 'moment';
import PhotoFragment from "@/components/PhotoFragment.vue";
import InfoBlock from "@/components/results/InfoBlock.vue";
import { faceRepository } from '@/models/face';
import searchedPortrait from '@/components/photoSearch/searchedPortrait.vue';
import _ from 'underscore';
import { watch } from 'vue';

export default {
    name: 'photoResultItem',
    setup() {
        return {
            moment
        }
    },
    props: ['result', 'editResults', 'isChecked', 'sourceName'],
    components: {
        searchedFace,
        PhotoFragment,
        InfoBlock,
        searchedPortrait
    },
    mixins: [filters],
    computed: {
        ...mapGetters('contacts', ['meta']),
        // meta() {
        //     return this.results.meta
        // }
    },
    data() {
        return {
            isPhotoSegments: false,
            displayDetail: false,
            portrait: null,
            selected: false
        }
    },
    methods: {
        getPortrait() {
            faceRepository.getByKey(this.meta.payload.portrait_id)
            .then(response => {
                this.portrait = response;
                this.$refs.facePreview.getRawImage(response.result.image_url);
                const faceParams = response.result.faces.length > 1 ? _.findWhere(response.result.faces,
                     {face_id: this.meta.payload.face_id}) : response.result.faces[0];
                this.$refs.facePreview.setParams(faceParams, response.result);
            });
        },
        initSelected() {
            this.selected = this.isChecked;
        },
        addToSelected() {
            if (this.selected) {
                this.$emit('select', this.result.data.id);
            } else {
                this.$emit('unselect', this.result.data.id);
            }
        },
        deleteResultModal() {
            this.$refs['delete-modal'].show();
        },
        hideModal() {
            this.$refs['delete-modal'].hide();
        },
        deleteSelectedResult() {
            this.$emit('childDelete', this.result.data.id);
            
        }
    },
    watch: {
        isChecked() {            
            this.initSelected();
        },
        selected(val) {
            if(val) {
                this.$emit('selected', this.result.data.id);
            }

        }
    },
    mounted() {
        this.getPortrait();
        this.initSelected();
    }
}
</script>
<style>
    .link-section {
        width: calc(100% - 32px);
        line-height: 20px;
    }
</style>